import SVG from "react-inlinesvg";
import NavLink from "../../Navlink";
import styles from "./leftSidebar.module.scss";
// //import Link from "next/link";
import siteInfo from "@/constants/siteInfo";
import sendGAEvent from "@/utils/gaEvent";

// components/Sidebar.js
export default function Sidebar({
  header_menu = [],
  setClickedMenuIndex = null,
  clickedMenuIndex = null,
  isRajasthan=false , isMadhya=false,
  isHR = false, isUP = false, isBH=false
}) {
 
  return (
    <aside
      className={`${styles.sidebar} hidden md:flex flex-col overflow-scroll h-full py-1 border-r-[1px] border-gray-200`}
    >
      {header_menu?.map((item, index) => (
        <div key={index}>
          <NavLink
            exact={true}
            role="button"
            id={item?.id}
            title={item?.title}
            href={`${item?.canonical_url ?? "/"}`}
            onMouseLeave={() => {
              if (!item.sub_menu || item?.sub_menu?.length === 0) {
                setClickedMenuIndex(null);
              }
            }}
            onMouseEnter={() => {
              if (item?.sub_menu?.length === 0) {
                setClickedMenuIndex(null);
              } else if (item?.sub_menu?.length > 0) {
                setClickedMenuIndex(index);
              }
            }}
            onClick={() => {
              let eventName = `Category List Click - ${item?.title} Tab`;
              sendGAEvent({
                eventName: eventName,
                pageTitle: document?.title,
                pageLocation: window?.location?.href,
              });
            }}
            activeclassname=" bg-primary-300 !text-primary border-l-4 border-l-primary"
            className={`flex items-center justify-between px-4 py-2 text-slate-600 ${isRajasthan ? 'hover:bg-rajasthanLight hover:text-rajasthanDark':isMadhya ? 'hover:bg-mpLight hover:text-mpDark':isHR ? 'hover:bg-hrLight hover:text-hrDark':isBH ? 'hover:bg-biharLight hover:text-biharDark':isUP ? 'hover:bg-upLight hover:text-upDark':'hover:bg-primary-300 hover:text-primary'}`}
          >
            <div className="flex items-center">
              {item?.svg && (
                <div className="w-4 h-4">
                  <SVG className="w-4 h-4" src={item?.svg} />
                </div>
              )}
              <span
                id={"dropdownHoverButton_" + index}
                data-dropdown-toggle={"dropdownHover_" + index}
                className="mx-2 font-medium"
              >
                {item?.title}
              </span>
            </div>
            {item?.sub_menu?.length > 0 && (
              <button
                onMouseEnter={() => {
                  setClickedMenuIndex(index);
                }}
                onMouseLeave={() => {
                  if (clickedMenuIndex !== index) {
                    setClickedMenuIndex(null);
                  }
                }}
                className="ml-2 flex items-center justify-center" // Adjust for proper spacing and alignment
                aria-label="Open submenu"
              >
                <SVG
                  className="w-[0.6rem] h-[0.6rem]"
                  src="/assets/icons/svg/rightArrow2.svg"
                />
                {/* Invisible clickable area around the arrow */}
                <span className="w-8 h-8 absolute"></span>
              </button>
            )}
          </NavLink>
        </div>
      ))}

      {/* Spacer div to push social links to the bottom */}
      <div className="flex-grow"></div>

      <div className="flex items-start justify-start gap-2 px-2 py-4">
        <a
          href={siteInfo.socialLinks.twitter}
          target="_blank"
          className={`w-8 h-8 ${isRajasthan ? 'text-rajasthanDark':isMadhya ? 'text-mpDark':isHR ? 'text-hrDark':isBH ? 'text-biharDark':isUP ? 'text-upDark':'text-primary'}`}
        >
          <SVG
            className="w-8 h-8"
            alt="twitter"
            loading="lazy"
            src="/assets/icons/svg/twitter.svg"
          />
        </a>
        <a
          href={siteInfo.socialLinks.facebook}
          target="_blank"
          className={`w-8 h-8 ${isRajasthan ? 'text-rajasthanDark':isMadhya ? 'text-mpDark':isHR ? 'text-hrDark':isBH ? 'text-biharDark':isUP ? 'text-upDark':'text-primary'}`}
        >
          <SVG
            className="w-8 h-8"
            alt="Facebook"
            loading="lazy"
            src="/assets/icons/svg/facebook.svg"
          />
        </a>
        <a
          href={siteInfo.socialLinks.instagram}
          target="_blank"
          className={`w-8 h-8 ${isRajasthan ? 'text-rajasthanDark':isMadhya ? 'text-mpDark':isHR ? 'text-hrDark':isBH ? 'text-biharDark':isUP ? 'text-upDark':'text-primary'}`}
        >
          <SVG
            className="w-8 h-8"
            alt="instagram"
            loading="lazy"
            src="/assets/icons/svg/instagram.svg"
          />
        </a>
        <a
          href={siteInfo.socialLinks.youtube}
          target="_blank"
          className={`w-8 h-8 ${isRajasthan ? 'text-rajasthanDark':isMadhya ? 'text-mpDark':isHR ? 'text-hrDark':isBH ? 'text-biharDark':isUP ? 'text-upDark':'text-primary'}`}
        >
          <SVG
            className="w-8 h-8"
            alt="youtube"
            loading="lazy"
            src="/assets/icons/svg/youtube.svg"
          />
        </a>
      </div>
    </aside>
  );
}
